import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/HorizontalNavigation/HorizontalNavigation.js'
import WebComponent from '../../WebComponent.js'
import Navigation from './Navigation.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'

export default class HorizontalNavigation extends WebComponent {

  get translationFile() {
    return './views/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => {
      this.reload()
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  getTemplate() {
    const authorizationFilter = new AuthorizationFilter()
    const items = [
      {text: this.i18n.translate('navigation.landing'), url: 'Landing', active: true},
      {text: this.i18n.translate('navigation.citesExport'), url: 'CITESExport', suburls: ['CITESExportDetail']},
      {text: this.i18n.translate('navigation.edav'), url: 'EDAV', suburls: ['EDAVDetail']},
      {text: this.i18n.translate('navigation.lmr'), url: 'LMR', suburls: ['LMRDetail']},
      {text: this.i18n.translate('navigation.iuu'), url: 'IUU', suburls: ['IUUDetail']},
      {text: this.i18n.translate('navigation.phyto'), url: 'Phyto', suburls: ['PhytoDetail']},
      {text: this.i18n.translate('navigation.module'), url: 'Module'}
    ]
      .filter(i => authorizationFilter.viewAllowed(i.url))

    return html`
      <isceco-horizontal-navigation .items="${items}">
      </isceco-horizontal-navigation>
    `
  }
}
customElements.define('management-frontend-horizontal-navigation', HorizontalNavigation)
