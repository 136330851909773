import {html} from '@isceco/widget-library2/external/lit'
import QueriesService from '../../../services/QueriesService.js'
import QueriesRowVariant from '../../Queries/QueriesRowVariant.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import DetailWebComponent from '../../Detail/DetailWebComponent.js'
import AnfrageStatusEnum from '../../../enum/AnfrageStatusEnum.js'
import VerarbeitungStatusEnum from '../../../enum/VerarbeitungStatusEnum.js'
import ModuleEnum from '../../../enum/ModuleEnum.js'
import TypeEnum from '../../../enum/TypeEnum.js'

export default class IUUDetail extends DetailWebComponent {
  get translationFile() {
    return './views/IUU/Detail/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.queriesService = new QueriesService()
    this.downloadAllowed = AuthorizationFilter.loggedInUserIsAdmin
    this.reload()
  }

  reload() {
    this.queriesService.read(getNavigationId())
      .then(json => {
        this.request = json
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-link icon="angle left"
                   text="${this.i18n.translate('page.back')}"
                   @click=${_ => history.back()}
      ></isceco-link>
      <isceco-title text="${this.i18n.translate('page.title')}">
        ${this.renderDownload(this.downloadAllowed, this.request.permitId)}
      </isceco-title>

      <div class="detail-content">
        <div>
          <section>
            <isceco-title text="${this.i18n.translate('permit')}" size="medium"></isceco-title>
            ${this.renderEntry('permit.permitId', this.request.permitId)}
            ${this.renderEntry('permit.usageId', this.request.usageId)}
            ${this.renderEntry('permit.sectorId', this.request.sectorId)}
            ${this.renderEntry('permit.transportMode', this.request.permit?.transportMode)}
            ${this.renderEntry('permit.zollstelle', this.request.permit?.zollstelle)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('position')}" size="medium"></isceco-title>
            ${this.renderEntry('position.countryOfOrigin', this.request.permit?.goodsDeclaration?.commodity?.countryOfOrigin)}
            ${this.renderEntry('position.countryOfDispatch', this.request.permit?.goodsDeclaration?.countryOfDispatch)}
            ${this.renderEntry('position.nationalCustomsTariffNumber', this.request.permit?.goodsDeclaration?.commodity?.nationalCustomsTariffNumber)}
            ${this.renderEntry('position.module', ModuleEnum.translate(this.request.module))}
            ${this.renderEntry('position.netMass', this.request.permit?.goodsDeclaration?.commodity?.netMass)}
            ${this.renderEntry('position.additionalMass', this.request.permit?.goodsDeclaration?.commodity?.additionalMass)}
            ${this.renderEntry('position.goodsDeclarationReferenceNumber', this.request.permit?.goodsDeclaration?.goodsDeclarationReferenceNumber)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('consignee')}" size="medium"></isceco-title>
            ${this.renderPerson(this.request.permit?.goodsDeclaration?.consignee)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('declarant')}" size="medium"></isceco-title>
            ${this.renderPerson(this.request.permit?.goodsDeclaration?.declarant)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('importer')}" size="medium"></isceco-title>
            ${this.renderPerson(this.request.permit?.goodsDeclaration?.importer)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('permitHolder')}" size="medium"></isceco-title>
            ${this.renderPerson(this.request.permit?.permitHolder)}
          </section>
        </div>

        <div>
          <section>
            <isceco-title text="${this.i18n.translate('request')}" size="medium"></isceco-title>
            ${this.renderEntry('request.time', this.renderDate(this.request.permit?.goodsDeclaration?.requestTimestamp))}
            ${this.renderEntry('request.type', TypeEnum.translate(this.request.type))}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('response')}" size="medium"></isceco-title>
            ${this.renderEntry('response.time', this.renderDate(this.request.response?.time))}
            <div class="${new QueriesRowVariant().get(this.request.response)}">
              ${this.renderEntry('response.ok', AnfrageStatusEnum.translate(this.request.response?.ok))}
              ${this.renderEntry('response.status', VerarbeitungStatusEnum.translate(this.request.response?.status))}
            </div>
            ${this.renderLogs(this.request.logs)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('status')}" size="medium"></isceco-title>
            ${this.renderComment(this.request.requestStatus, this.request.id)}
          </section>

          <section>
            <isceco-title text="${this.i18n.translate('errors')}" size="medium"></isceco-title>
            ${this.renderErrors(this.request.response?.errors)}
          </section>
        </div>
      </div>
    `
  }
}

customElements.define('management-frontend-iuu-detail', IUUDetail)
