import VerarbeitungStatusEnum from '../../enum/VerarbeitungStatusEnum.js'

export default class QueriesRowVariant {
  get(row) {
    return QueriesRowVariant.color(row)
  }

  static color(row) {
    if (row.ok) {
      if (row.status === VerarbeitungStatusEnum.KEYS.COMPLETED_SUCCESS) {
        return 'success clickable'
      } else {
        return 'warning clickable'
      }
    } else {
      return 'error clickable'
    }
  }
}
